<template>
  <div class="TaskJob">
    <el-row>
      <el-select v-model="versions" placeholder="请选择节点版本" class="mr_20">
        <el-option label="versions2&2.5" :value="2"> </el-option>
        <el-option label="versions3" :value="3"> </el-option>
      </el-select>

      <el-button @click="findUnitAlarmByUserFun" type="primary">查询</el-button>
    </el-row>
    <MyTable :dataSource="dataSource" :columns="columns" :loading="loading" :total="total" index border stripe :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" @handleClick="handleClick"></MyTable>
    <el-dialog class="disposeAlarm" title="警情处理" center :visible.sync="dialogVisibleDispose" :before-close="handleClose">
      <Dispose v-if="dialogVisibleDispose" :pkAlarmId="pkAlarmId" :fkTaskId="fkTaskId"></Dispose>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import Dispose from './Dispose.vue'
import { findUserAlarm } from '@/api/Alarm.js'
const columns = [
  {
    prop: 'projectName',
    label: '工程名称',
    type: 'text',
    width: '300px',
    align: 'center',
  },

  {
    prop: 'pointName',
    label: '监测节点',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'dataBatch',
    label: '数据批次',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '报警时间',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmValue',
    label: '报警值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'safeState',
    label: '报警等级',
    type: 'icon',
    align: 'center',
  },
  {
    prop: 'edit',
    label: '报警处理',
    align: 'center',
    type: 'button',
    width: 100,
    buttonInfo: {
      text: '处理',
      type: 'warning',
      size: 'mini',
    },
  },
  {
    prop: 'view',
    label: '工程详情',
    align: 'center',
    type: 'button',
    width: 100,
    buttonInfo: {
      text: '查看',
      type: 'success',
      size: 'mini',
    },
  },
]

const dataSource = []
export default {
  name: 'AlarmList',
  components: { MyTable, Dispose },
  data() {
    return {
      versions: 2,
      projectName: null,
      currentPage: 0,
      pageSize: 10,
      total: null,
      loading: true,
      dataSource,
      columns,
      stateId: 0,
      pkTaskId: null,
      hostId: null,
      hostList: [],
      disabled: false,
      value: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      timeQuantum: [],
      dialogVisibleDispose: false,
      itemType: 'F',
      pkAlarmId: null,
      fkTaskId: null,
    }
  },

  methods: {
    async findUnitAlarmByUserFun() {
      this.loading = true
      const { data: res } = await findUserAlarm(this.$store.state.userId, 0, this.currentPage, this.pageSize, this.versions)
      console.log(res.data.list)
      this.total = res.data.total
      res.data.list.forEach((item) => {
        item.pointName = item.point.pointName
        item.projectName = item.task.projectName
      })
      this.dataSource = res.data.list
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.findUnitAlarmByUserFun()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findUnitAlarmByUserFun()
    },
    handleClick(row, type) {
      switch (type) {
        case 'edit':
          console.log(row.id, '修改')
          this.fkTaskId = row.fkTaskId - 0
          this.pkAlarmId = row.id - 0
          this.dialogVisibleDispose = true
          break
        case 'view':
          window.open('/ProjectMonitor/' + (row.fkTaskId - 0) + '/Condition')
          console.log('跳转')
          break
      }
    },
    handleSelectItem() {},
    handleClose() {
      this.dialogVisibleDispose = false
    },
  },
  created() {
    this.findUnitAlarmByUserFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.TaskJob {
  padding: 10px;
  width: calc(100% - 20px);
  .el-row {
    min-width: 1600px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .mr_20 {
    margin-right: 20px;
  }
  .el-input {
    width: 300px;
  }
}

.addTask ::v-deep .el-dialog {
  width: 800px;
  height: 600px;
}
.upload ::v-deep .el-dialog {
  width: 500px;
  height: 800px;
}
.upState ::v-deep .el-dialog {
  width: 350px !important;
  height: 240px;
}

.TaskInfo ::v-deep .el-dialog {
  width: 1360px;
  height: 1000px;

  .el-dialog__body {
    padding: 0 25px;
  }
}

.TaskJob {
  ::v-deep .el-message-box__content {
    height: 200px !important;
  }
}

.el-message-box__message {
  height: 200px;
}

.disposeAlarm {
  ::v-deep .el-dialog {
    padding: 0;
    width: 300px;
    height: 280px;
  }
}
</style>
